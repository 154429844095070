function Terms() {


  return (


  <div>
    <p>These "Terms and Conditions" last update was made on:  November 21, 2021.</p>
    <div>
    <h2>1. Introduction</h2>
    <div>Mr. Dick is a collection of 690 beautiful algorithmically generated Dick avatar NFTs.</div>
    <div>The Mr. Dick Website allows you to participate in minting, buying and selling non-fungible tokens ("NFTs").</div>
  </div>
  <div>
    <h2>2. Definitions</h2>
    <div>
      <div>"Mr. Dick" refers to the original collection of 690 algorithmically generated 2D avatars.</div>
      <div>"Mr. Dick NFT" is defined as any individual art, design, 2D drawings that may be associated
        with a Mr. Dick NFT that you Own. </div>
      <div>"Own" is defined as possession of a Mr. Dick NFT in your Ethereum wallet, where proof of such possession
        is recorded on the blockchain. </div>
      <div>"Traits" are defined as the distinguishing characteristics and proprietary individually layered art files
        associated with each Mr. Dick NFT. These include but are not limited to Body, Face, Headwear,
        Clothes, and Background.</div>
      <div>"Website" refers to Mr. Dick website located at "https://mrdick.io"</div>
    </div>
  </div>
  
  <div>
    <h2>3. Eligibility</h2>
    <div>Mr. Dick has sole and absolute discretion to allow or disallow your access to the Mr. Dick "Website". <br />
      By agreeing to these Terms, you represent and warrant that:
      <p>
        # You are at least 18 years of age;<br />
        # You have the full right, power, and authority to agree to these Terms;<br />
        # You are not impersonating any other person; <br />
        # You are compliant with all Applicable Laws to which you are subject; <br />
      </p>
    </div>
  </div>

  <div>
    <h2>4. Intellectual Property</h2>
    <div>
      Unless otherwise indicated by us, the Site, all content, and other materials contained therein, including, without limitation, the Mr. Dick logo, and all designs, text graphics, pictures, the look and feel, the smart contract code, information, data, software, and anything else not specifically granted by any following licenses are the proprietary property of Mr. Dick or our affiliates, licensors.
      The Mr. Dick logo and any Mr. Dick product or service names, logos, or slogans that may appear on the Site or elsewhere are the proprietary property of Mr. Dick and may not be copied, imitated or used, in whole or in part, without our prior written permission.
    </div>
  </div>
  <div>
    <h2>5. Your Ownership of the Mr. Dick NFT</h2>
    <div>
      <div>By holding a Mr. Dick NFT in your Ethereum wallet, you own the NFT and have the right to sell or give away the NFT.<br />
      Owning a Mr. Dick NFT automatically qualifies you to participate in the pre-sale of our upcoming projects.<br />
      If the NFT is associated with a Sale Item, you will have a worldwide, perpetual, exclusive, transferable, licence to use, copy, and display the Sale Item for your NFT, for so long as you own the NFT.</div>
      <div>All NFTs ownership is verified cryptographically on the Ethereum Blockchain. This is proof of ownership and
        grants rights within this document. Once the NFT leaves ownership, all Personal and Commercial use terms are
        revoked.</div>
    </div>
  </div>
  <div>
    <h2>6. Personal Use</h2>
    <div>
      <div>Subject to compliance with these terms, Mr. Dick grants you a royalty-free license to use, copy, display,
        and modify the Mr. Dick NFTs that you own. This usage is granted world-wide. </div>
      <div>This usage is for the limited purposes of: </div>
      <p>
        # Personal and Non-Commercial Use;<br />
        # Display and usage of your NFT on External Websites;<br />
        # The use of your NFT as an avatar;<br />
        # Ability to Buy, Sell, and Trade your NFT;<br />
      </p>
    </div>
  </div>
  <div>
    <h2>7. Commercial and Derivative Rights</h2>
    <div>
      <div>Mr. Dick expressly prohibits the use of any proprietary, individual traits or crafted layers in any and
        all other projects. Mr. Dick also prohibits the sale or promotion of counterfeit NFTs or NFT collections.
        Counterfeit NFTs or collections contain art that is identical to or substantially indistinguishable from the
        original Mr. Dick collection, including:</div>
        <p>
        # NFTs described as knock off, replica, imitation, clone, faux, fake, mirror image, or similar terms when
          referring to an NFT or NFT Collection in an attempt to pass themselves off as genuine Mr. Dick creations.<br />
          <br />
          # Non-genuine products that mimic Mr. Dick features or proprietary traits in an attempt to pass themselves
          off as genuine Mr. Dick creations.
        </p>        
      <div>Otherwise, you are free to use your Mr. Dick NFT in the fullest sense of commercial and derivative use cases.
      </div>
      <div>However, user shall not use any Mr. Dick Licensed Materials in any way, or in connection with any
        material, which is unlawful, fraudulent, libelous, defamatory, obscene, pornographic, profane, threatening,
        abusive, hateful, offensive or otherwise objectionable or unreasonable or infringing upon any laws or
        regulations or intellectual property rights or proprietary rights or confidentiality obligations and you shall
        indemnify and defend Mr. Dick against any claims, damages, proceedings, loss or costs arising from such use.
        User shall not use the Mr. Dick Licensed Materials in any way that could be construed as being adverse or
        derogatory to the image of Mr. Dick or any of its subjects. </div>
    </div>
  </div>
  <div>
    <h2>8. Mr. Dick NFTs are NOT Investment Vehicles</h2>
    <div>Mr. Dick NFT are collectible digital art pieces that also function as fun, Non-Fungible Tokens for you to
      collect. They were created as art pieces intended for people to enjoy by collecting, not as a financial
      instrument. Mr. Dick makes absolutely no promises or guarantees regarding the value of Mr. Dick NFTs aside
      from the one that we will strive to do the best for the project and the community.</div>
  </div>
  <div>
    <h2>9. Risks</h2>
    <div>
    You understand and agree that buying and selling non-fungible tokens ("NFTs") is subject to certain risks including without limitation:<br />
    <p>
    # Price and liquidity of blockchain assets, including the NFTs, are extremely volatile and may be subject to fluctuations; <br />
    # Fluctuations in the price of other digital assets could materially and adversely affect the NFTs; <br />
    # Legislative and regulatory changes or actions may adversely affect the use, transfer, and value of the NFTs; <br />
    # NFTs are not legal tender and are not back by any government; <br />
    # Transactions involving NFTs may be irreversible, and losses due to fraudulent or accidental transactions may not be recoverable; <br />
    # The value of NFTs may be derived from the continued willingness of market participants to exchange fiat currency or digital assets for NFTs, and therefore the value of NFTs is subject to the potential for permanent or total loss of value should the market for NFTs disappear; <br />
    # NFTs are subject to the risk of fraud, counterfeiting, cyber-attacks and other technological difficulties which may prevent access to or use of your NFTs; <br />
    You understand and agree that you are solely responsible for determining the nature, potential value, suitability, and appropriateness of these risks for yourself.<br />
    Mr. Dick does not give any advice or recommendations regarding the NFTs.
    </p>
    </div>
  </div>
  <div>
    <h2>10. Taxes</h2>
    <div>You agree that you are solely responsible for determining what, if any, taxes apply from selling or owning a Mr. Dick NFT. <br />
     Mr. Dick is not responsible for determining the taxes that may apply to your NFT transactions.</div>
  </div>
  <div>
    <h2>11. Limitation of Liability</h2>
    <div>
      TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL MR. DICK BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM THESE TERMS, THE WEBSITE, PRODUCTS OR THIRD PARTY SITES AND PRODUCTS, OR FOR ANY DAMAGES RELATED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF MR. DICK HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF THE SITE, PRODUCTS OR THIRD-PARTY SITES AND PRODUCTS ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA RESULTING THEREFROM. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF MR. DICK ARISING OUT OF OR IN ANY WAY RELATED TO THESE TERMS, THE ACCESS AND USE OF THE SITE, CONTENT NFTS OR ANY PRODUCT OR SERVICES PURCHASES ON THE SITE EXCEED US$100. THE FOREGOING LIMITATIONS OF LIABILITY SHALL NOT APPLY TO LIABILITY OF MR. DICK FOR PERSONAL INJURY CAUSED BY MR. DICK’S NEGLIGENCE OR ANY INJURY CAUSED BY MR. DICK’S FRAUD OR FRAUDULENT MISREPRESENTATION.
    </div>
  </div>
  <div>
    <h2>12. Children</h2>
    <div>The Mr. Dick Project is not targeted towards children. You agree that you are over the age of 18, or above
      the legal age of your jurisdiction, whichever is greater. </div>
  </div>
  <div>
    <h2>These Terms are Subject to Change</h2>
    <div>We may revise these Terms from time to time, but the most current version will always be on
      https://mrdick.io/terms. You are responsible for reviewing and becoming familiar with any such modifications.
      Revisions deemed material, in the sole discretion of Mr. Dick, will include a notification via Twitter or
      Discord. By continuing to access the Mr. Dick website and by holding a Mr. Dick NFT, you agree to be bound
      by the current Terms.</div>
  </div>
  </div>

  );
};

export default Terms;

