require("dotenv").config();
const alchemyKey = "https://eth-mainnet.alchemyapi.io/v2/aQSEvo-X4dWFJiarGx-gKk6JadUFzMi3";
//const alchemyKey = "https://eth-goerli.alchemyapi.io/v2/aQSEvo-X4dWFJiarGx-gKk6JadUFzMi3";
const contractABI = require("../contract-abi.json");
const contractAddress = "0xC92F859Cafd2A82B44d96289eC2839a29f4E45d5";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

const axios = require('axios');


export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: "", //👆🏽 Write a message in the text-field above.
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "" // "👆🏽 Write a message in the text-field above.",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};



export const mintNFT = async (cost) => {
  cost = parseInt(cost);

  if (window.ethereum == undefined){
    return {
      success: false,
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }

  if (window.ethereum.selectedAddress == undefined){
    return {
      success: false,
      status: "🦊 Connect to Metamask using the top right button.",
    };
  }


  var pwd = "none";
  try {
    pwd = window.location.href.split("p=")[1].split("&")[0];
  } catch (error) {
    return {
      success: false,
      status: "😥 We're currently in presale. Please use an invite link to enable minting.",
    };
  }
  
  var response = await axios.get('https://us-central1-mrdick-bdea6.cloudfunctions.net/signTransaction?nocache=' + Date.now() + "&pwd=" + pwd);
  if (response.status == 200){
    
    // handle success
    console.log(response);
    var data = response.data;
    if (data.error != null){
      return {
        success: false,
        status: "😥 " + data.error,
      };
    }

    if (data.messageHash == "" || data.signature == "" || data.messageHash == undefined || data.signature == undefined){
      return {
        success: false,
        status: "😥 Signature Invalid",
      };
    }
    
/*
    if (window.ethereum.selectedAddress.toLowerCase() === "0xac4Cd2811E5b8aDBe9b69912Bc0c384fBE322CED".toLowerCase()){
      cost = 0;
    }*/

    var gasLimit = 0;
    /*
    var gasPrice = await web3.eth.getGasPrice();
    try {
      gasLimit = await new web3.eth.estimateGas({
        to: contractAddress,
        from: window.ethereum.selectedAddress, // must match user's active address.
        data: window.contract.methods
        .mint(data.messageHash,data.signature)
        .encodeABI(),
        value: "0x" + (cost).toString(16)
      })
    } catch (error) {
      console.log(error);
      return {
        success: false,
        status: error.message,
      };
    }
    
    console.log("calculated gas fees:" + gasLimit);
    */


    if (gasLimit == 0){
      gasLimit = 277963; // calculated from metamask
    } else {
      gasLimit = Math.floor(gasLimit * 1.3); // safety margin
    }
    console.log("Gas Limit:" + gasLimit);

    //var cost = 0;
    //debugger;
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
      to: contractAddress, // Required except during contract publications.
      from: window.ethereum.selectedAddress, // must match user's active address.
      data: window.contract.methods
        .mint(data.messageHash,data.signature)
        .encodeABI(),
      value: "0x" + (cost).toString(16),
      gas: "0x" + (gasLimit).toString(16)
    };
   

    try {
    
      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      });
      return {
        success: true,
        status:  <a target="_blank" href={`https://etherscan.io/tx/`+txHash}>
        ✅ Check out your transaction on Etherscan: https://etherscan.io/tx/{txHash}
          txHash
          </a>
          
      };
    } catch (error) {
      if (error.message.includes("from")){
        error.message = "Hint: Wallet locked."
      }
      return {
        success: false,
        status: "😥 Something went wrong. " + error.message,
      };
    }

  }
 


  
  
};



export const getSupply = async () => {

  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  try {
    var supply = await window.contract.methods.totalSupply().call();
    return supply;
    
  } catch (error) {
    console.log(error);
  }
  
};


export const getCost = async () => {

  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  try {
    var cost = await window.contract.methods.cost().call();
    return cost;
    
  } catch (error) {
    console.log(error);
  }
  
};







