function Cookie() {


  const acceptCookiePressed = async () => {
    localStorage.setItem("cookie","true");
    window.location.reload();
  };


  return (


  
  <div id="cookie_modal">
    <h2>Mr. Dick Requires Cookies</h2>
    <div>Mr. Dick requires cookies to know how you interact with the Mr.Dick website and to improve your browsing experience.</div>
    <p>
      <div id="cookie_button" onClick={acceptCookiePressed}>
         Continue         
      </div>
    </p>

  </div>
  

  );
};

export default Cookie;

