function FAQ() {


  return (


  <div>
    <div>
    <h2>FAQ</h2>
  </div>
  {/*
  <div>
    <h3>1. Will there ever be more than 690 Dicks?</h3>
    <div>
      No, the original Mr. Dick collection is limited to 690 pieces.<br />
       We will never release more pieces of this artwork.
    </div>
  </div>
  */
  }
  <div>
    <h3>1. How can I participate in the pre-sale?</h3>
    <div>
      The pre-sale is limited to 69 Dicks.<br />
      To qualify for the pre-sale:<br />
      # Follow us on <a class="black" href="https://twitter.com/RealMrDickNft" target="_blank">Twitter</a><br />
      # Like and Retweet our Tweets<br />
      # Join our <a class="black" href="https://t.me/mrdick_official" target="_blank">Telegram</a> group<br />
      There is no guarantee to parcitipate in the pre-sale.<br />
     {/* Pre-sale is limited to 1 NFT per user.<br /> */}
    </div>
  </div>
  <div>
    <h3>2. When does the pre-sale start?</h3>
    <div>
      Pre-sale starts 10. December 2021 at 00:00 CET. Check our presale listing: <a class="black" href="https://nftcalendar.io/event/mr-dick-presale/" target="_blank">nftcalendar.io</a><br />
      Once you qualified for the pre-sale we'll send you a link to the minting page.<br />
    </div>
  </div>
  <div>
    <h3>3. Can I choose how my Dick looks like?</h3>
    <div>
      No, at the time of minting you will receive a randomly generated dick.<br />
      The adult look of your Dick is algorithmically defined.<br />
      You don't have any influence over how your Dick grows up.
    </div>
  </div>
  <div>
    <h3>4. Can I get a refund for my Dick?</h3>
    <div>
      No, this is not possible, you can however sell your Dick at the second market.<br />
      Please visit <a class="black" href="https://opensea.io">opensea.io</a>  to do so.
    </div>
  </div>
  <div>
    <h3>5. When will my Dick grow?</h3>
    <div>
      Your Dick starts growing as soon as all Dicks are minted.
    </div>
  </div>
  <div>
    <h3>6. How long does it need for my Dick to grow?</h3>
    <div>
      Once all Dicks are minted it will take 1 Year for your Dick to turn into its final state.
    </div>
  </div>
  <div>
    <h3>7. Can I sell my Dick while it is growing?</h3>
    <div>
      Yes, please visit <a class="black" href="https://opensea.io">opensea.io</a> to do so.
    </div>
  </div>
  <div>
    <h3>8. Are all baby Dicks unique?</h3>
    <div>
      No, just like in real life, babies look very similar.
    </div>
  </div>
  <div>
    <h3>9. Are all grown Dicks unique?</h3>
    <div>
      Yes, all adult Dicks are unique.
    </div>
  </div>
  <div>
    <h3>10. Will all Dicks grow?</h3>
    <div>
      No, there is a very limited number of Dicks that will never grow.
    </div>
  </div>
  <div>
    <h3>11. How many Dicks grow into a King?</h3>
    <div>
      Out of the 690 Dicks 30 will become a King.
    </div>
  </div>
  <div>
    <h3>12. Are we joking?</h3>
    <div>
      No we are dead serious :)
    </div>
  </div>
  
  </div>

  );
};

export default FAQ;

