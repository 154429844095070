import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,
  getSupply,
  getCost
} from "./util/interact.js";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';



const Home = (props) => {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");

  var [totalSupply, setTotalSupply] = useState(0);
  var [cost, setCost] = useState(0);
  var [mintButtonLabel, setMintButtonLabel] = useState("");

  var [countDownDays, setCountdownDays] = useState("");
  var [countDownHours, setCountdownHours] = useState("");
  var [countDownMinutes, setCountdownMinutes] = useState("");
  var [countDownSeconds, setCountdownSeconds] = useState("");
  


 

  if (window.countDownDate == undefined){
    window.countDownDate = Date.parse('1 Jan 2022 00:00:00 GMT');
  }
  
  

  clearInterval(window.countdown);
  window.countdown = setInterval(function() {
    updateCountdown();
  }, 1000);
  
  

  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();

    updateCountdown();
    getTotalSupply();

    getMintCost();
    

    setWallet(address);
    setStatus(status);

    addWalletListener();
  }, []);

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          //setStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  const updateCountdown = () => {
    var now = new Date().getTime();
    var timeleft = window.countDownDate - now;

    if (props.presale == "true" ){
      timeleft = 0;
    }
    //timeleft = 0; //todo: remove
    if (timeleft <= 0){
      var mint_now = document.getElementById("mint_now");
      var mint_container = document.getElementById("mint_container");
      var countdown_container = document.getElementById("countdown_container");
      if (mint_container != undefined) {
        mint_now.style.display = "block";
        mint_container.style.display = "block";
        countdown_container.style.display = "none";
      } 
    }
    setCountdownDays(Math.floor(timeleft / (1000 * 60 * 60 * 24)));
    setCountdownHours(Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    setCountdownMinutes(Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60)));
    setCountdownSeconds(Math.floor((timeleft % (1000 * 60)) / 1000));
  }


  const updateProgressBar = (supply) => {
    var percentage = 100 / 690 * parseInt(supply);
    document.getElementById("progress_bar").style.width = percentage + "%";
  }

  const getTotalSupply = async () => {
    var supply = await getSupply();
    setTotalSupply(supply);
    updateProgressBar(supply);
  }

  const getMintCost = async () => {
    setMintButtonLabel("Loading...");
    var cost = await getCost();
    setCost(cost);
    setMintButtonLabel("Mint for " + (cost / 1000000000000000000) + " ETH");
  }

  
  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async () => {
    setMintButtonLabel("Minting...");
    const { success, status } = await mintNFT(cost);
    getMintCost();
    setStatus(status);
    if (success) {
      setTotalSupply(parseInt(totalSupply) + 1);
      updateProgressBar(parseInt(totalSupply) + 1);
    }
  };


  const scrollToMint = () => {
    document.getElementById('mint_button').scrollIntoView();
  }

  return (


    <div>


    <div id="container">
    
    <h1 id="home">Mr. Dick</h1>
          <p class="semi_size">
              Meet Mr. Dick, the original collection of 690 beautiful algorithmically generated Dicks.<br />
              All art is made by hand in a 24px format.<br />
              Help us to support charities fighting hunger and sexually transmitted diseases in Africa.
          </p>


        <div id="wallet_button" onClick={connectWalletPressed}>
          {walletAddress.length > 0 ? (
            "Connected: " +
            String(walletAddress).substring(0, 6) +
            "..." +
            String(walletAddress).substring(38)
          ) : (
            <span>Connect Wallet</span>
          )}
        </div>
    
    
    {
    /*      
    <p className="semi_size">
        We promise to never release more than the initial drop of 690 Dicks.
    </p>
  
    <h3>Artwork Reveal</h3>
    <p className="semi_size">
        We will reveal the artwork after all Dicks are born (minted).
        This addresses the problem that the scare Dicks get bought up while the others don't find a home.
        <br />
        <br />
        Never ever should a Dick feel alone.
    </p> 
    */
    }

    <h3>Mr. Dicks unique Feature:</h3>
    <p>
    Watch how your Dick grows.<br />The Mr. Dick NFT collection has a feature that sets Mr. Dick apart from any other NFT out there. Instead of revealing the artwork at once, we will reveal it layer by layer.
    You can literally watch how your Dick grows into a rare king or a sick junkie.</p>
    

    <div id="mint_now">
      <a class="mint_now_button" onClick={scrollToMint}>
        Mint now!
      </a>
    </div>

    
    {
    /*
    <p>
        This feature makes the Mr. Dick NFT collection unique and addresses the issue that the scare Dicks get bought up while the others don't find a home.
    </p>
    <p>
      Never ever should a Dick feel alone.
    </p>
    */
    }
    <p>
      <h3><a class="" href="https://opensea.io/collection/mrdick">Watch the Dicks on Opensea.io</a></h3>
    </p>
    <img src="./mrdickgif2.gif" /><br />
    <br />
    A small baby Dick might evolve into a king.<br />
    (Background color and race is defined at the time of minting your Dick).<br />
    <br />
    {

      /*
      <img src="./118.png" /><br />
      */

    }
    

    
     

    <h2>Fee's</h2>
    <h3>Minting</h3>
    <p>
      The current mint price is {(cost / 1000000000000000000).toFixed(2)} ETH.<br />
    </p>
    <h3>Reselling</h3>
    <p>
      Mr. Dick has a 5% reselling fee (+ Opensea fees) which is used for marketing and development.
    </p>
   



    <h2>Mr. Dick's Roadmap</h2>

    <h3>Phase 1 Q4 2021</h3>
    <p>
        # Create artwork<br />
        # Create smart contract<br />
        # Create website<br />
        # Presale<br />
        # Grow the community (We're here)<br />
    </p>


    <h3>Phase 2 Q2 2022</h3>
    <p>
        # Public Minting via MrDick.io website
    </p>


    <h3>Phase 3</h3>
    <p>
        # All Dicks minted.<br />
        # Your Dick starts growing up.<br />
        # Artwork will be revealed.
    </p>

    <h3>Phase 4</h3>
    <p>
        # All Dicks grown up.<br />
        # Much more to come!<br />
    </p>

    

    {/*
    <h3>Phase 5</h3>
    <p>
        
        
        # The Real Mr. Dick goes international.<br />
        # See your Dick in art exibitions in major cities across the globe.
        
    </p>
    */
    }


    
    <h3>How can I participate in the pre-sale?</h3>
    <p>
      The presale is limited to 69 Dicks.<br />
      To qualify for the pre-sale:<br />
      # Follow us on <a class="black" href="https://twitter.com/RealMrDickNft" target="_blank">Twitter</a><br />
      # Like and Retweet our Tweets<br />
      # Join our <a class="black" href="https://t.me/mrdick_official" target="_blank">Telegram</a> group<br />
      <a class="black" href="/faq">Learn more in our FAQ</a>
    </p>



    <div id="progress_container">
      <h2 className="white">
        Total Supply:
      </h2>
      <div id="progress_box">
        <div id="progress_label">{totalSupply} / 690 Dicks</div>
        <div id="progress_bar"></div>
      </div>
    </div>




    <div id="countdown_container">
      <h2 className="white">
        Public Minting:
      </h2>
      <span className="countdown">{countDownDays}</span><span className="countdown">{countDownHours}</span><span className="countdown">{countDownMinutes}</span><span className="countdown">{countDownSeconds}</span>
      <div>
        <span className="countdownLabel">Days</span><span className="countdownLabel">Hours</span><span className="countdownLabel">Min</span><span className="countdownLabel">Sec</span>
      </div>
    </div>
  
   

    <div id="mint"></div>
    <div id="mint_container">
      <h2>Mint your Mr. Dick now</h2>
      <a id="mint_button" onClick={onMintPressed}>
        { mintButtonLabel } 
      </a>
      <p id="status" style={{ color: "red" }}>
        {status}
      </p>
    </div>

    <div>
      <h3>Mr. Dick lives on the Ethereum Mainnet</h3>
      <p><a class="black" href="https://etherscan.io/address/0xC92F859Cafd2A82B44d96289eC2839a29f4E45d5">0xC92F859Cafd2A82B44d96289eC2839a29f4E45d5</a></p>
    </div>


    {
    /*
    <a id="mint_button" onClick={onMintPressed}>
        Mint for 0.05 ETH
    </a>
    */
    }
</div>






    </div>
  );
};

export default Home;
