import './App.css';
import Home from './Home';
import FAQ from './FAQ';
import Terms from './Terms';
import Privacy from './Privacy';
import Cookie from './Cookie';
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons';


function App() {

  var cookieModal = false;
  if (localStorage.getItem("cookie") == undefined){
    cookieModal = true;
  }
  

  return (
    <Router>
      <div className="App">
          


        { cookieModal == true &&
          <Cookie cookieModal={true} />
        } 

        { cookieModal == false &&
        
          <div>
            <header>
              <a href="/">Home</a>
              <a href="/faq">FAQ</a>
              <a href="/#mint">Mint</a>
            </header>

            <Routes>
              <Route path="/" element={ <Home />} />  
              <Route path="/faq" element={ <FAQ />} />
              <Route path="/terms" element={ <Terms />} />
              <Route path="/privacy" element={ <Privacy />} />
              <Route path="/presale" element={ <Home presale="true"/>} />
            </Routes>


            <footer>
              <p>
                  Happy cumming!<br />
                  Handmande with love.
              </p>
              <a href="/">Home</a>
              <a href="/faq">FAQ</a>
              <a href="/#mint">Mint</a>
              <a href="/terms">Terms</a>
              <a href="/privacy">Privacy</a>

              <p>
                <a href="https://twitter.com/RealMrDickNft" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a> <a href="https://t.me/mrdick_official" target="_blank"><FontAwesomeIcon icon={faTelegram} /></a>
              </p>

            </footer>
          </div>
        }

      </div>
    </Router>
  );



}

export default App;
